import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";

const SubNavMobile = loadable(() => import("./SubNavMobile"));

function Mobile({ list = [], navigations }) {
  const [menuRight, setMenuRight] = useState([]);

  useEffect(() => {
    const obj = navigations.find((n) => n.position === "mobile-right");
    if (obj) setMenuRight(obj.children || []);
  }, []);

  function checkMobileModalStatus(e) {
    e.preventDefault();
    const el = document.getElementById("mobile");
    if (el.classList.contains("uk-open")) {
      window.UIkit.toggle(el).toggle();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <div className="uk-hidden@l">
      <div onClick={checkMobileModalStatus}>
        <Link to="/" className="uk-logo">
          <div
            className="uk-position-top-left uk-position-fixed"
            style={{ zIndex: 1011 }}
          >
            <img
              width={190}
              data-src="/kutter-logo.png"
              alt="Kutter Logo"
              className="uk-padding uk-padding-remove-horizontal uk-padding-remove-top lazyload"
            />
          </div>
        </Link>
      </div>
      <div
        id="mobile"
        className="uk-modal-full"
        style={{ backgroundColor: "#fff" }}
        data-uk-modal=""
      >
        <div
          className="uk-modal-dialog"
          data-uk-height-viewport="offset-bottom: 25"
        >
          <section className="uk-section uk-section-default uk-section-xlarge">
            <ul className="uk-width-1-1" data-uk-accordion="">
              {list.map((item) => {
                return (
                  <li key={item._id}>
                    <a
                      className="uk-accordion-title uk-h2 uk-background-primary uk-margin-remove"
                      style={{ padding: "20px 25px", color: "#fff" }}
                      href="#"
                    >
                      {item.label}
                    </a>
                    <div
                      className="uk-accordion-content"
                      style={{ marginTop: 8 }}
                    >
                      <SubNavMobile list={item.children} item={item} />
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      </div>
      <div
        className="uk-position-bottom uk-position-fixed"
        style={{ zIndex: 9999 }}
      >
        <nav className="uk-navbar uk-navbar-container mobile uk-position-z-index ">
          <div className="uk-navbar-left">
            <ul className="uk-navbar-nav">
              <li>
                <a
                  className="uk-navbar-toggle"
                  style={{ color: "white" }}
                  data-uk-navbar-toggle-icon="ratio: 1.5"
                  href="#mobile"
                  data-uk-toggle=""
                  aria-label="Mobiles Menu"
                />
              </li>
            </ul>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav">
              {menuRight.map((item) => (
                <li key={item._id} onClick={checkMobileModalStatus}>
                  <Link
                    to={(item.page && item.page.url) || item.url}
                    style={{ color: "white" }}
                  >
                    {item.icon && (
                      <i
                        className="uk-icon"
                        data-uk-icon={`icon: ${item.icon}; ratio: 1.5`}
                      />
                    )}
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

function mapStateToProps({ navigations }) {
  return { navigations };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);
